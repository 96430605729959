import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {faCalendarAlt, faInfoCircle, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {AbstractControl, FormArray, FormControl, Validators} from '@angular/forms';
import {ExtradataInstance, FullExtraData} from '../../api/benutzermanagement/models';
import {FormControlWithHints} from '../../shared/form-helpers';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {ExtradataInstanceReducerState, getExtradataInstanceFeatureState} from '../../reducers/userdata.reducer';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {Store} from '@ngrx/store';
import {AppToastService} from '../../toast/app-toast.service';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingState} from '../../shared/reducer.includes';
import {MODULE_ROOT} from '../root.config';
import {animate, style, transition, trigger} from '@angular/animations';
import {v4 as uuidv4} from 'uuid';
import {ibanValidator} from '../../shared/validators';

export class FormControlWithId extends FormControl {
  public readonly id = uuidv4();

  constructor() {
    super();
  }
}

export class FormArrayWithEdi extends FormArray {
  public edi: ExtradataInstance;
}

export class FormControlWithEdi extends FormControl {
  public edi: ExtradataInstance;
}

class FormControlWithHintsAndEdi extends FormControlWithHints {
  public edi: ExtradataInstance;
}

interface UpdateResult {
  controlName: string;
  control: AbstractControl;
  errors?: string[];
}

@Component({
  selector: 'san-bankdaten',
  templateUrl: './bankdaten.component.html',
  styleUrls: ['./bankdaten.component.scss'],
  animations: [
    trigger('vis', [
      transition(':enter', [
        style({'max-height': 0, overflow: 'hidden'}),
        animate('0.5s ease', style({'max-height': '100%'})),
        style({'max-height': 'unset'}),
      ]),
      transition(':leave', [
        style({'max-height': '100px', overflow: 'hidden'}),
        animate('0.3s ease', style({'max-height': 0})),
      ]),
    ]),
  ],
})
export class BankdatenComponent implements OnInit, OnDestroy {

  constructor(private benutzerApi: BenutzermanagementAPIService, private store: Store,
              private toastService: AppToastService, private popupService: NgbModal) {
  }

  @HostBinding('class.card') cCard = true;
  @HostBinding('class.w-100') cW100 = true;
  @HostBinding('class.mt-3') cMt3 = true;

  @Input() onlyKeys: string[] = null;
  @Input() forceRequired: string[] = null;
  @Input() speichernBtnVisible = true;

  @Output() saving = new EventEmitter<any>();
  @Output() saved = new EventEmitter<any>();
  @Output() saveError = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('data-ready') dataReady = new EventEmitter<any>();
  // @Output('data-valid') dataValid = new EventEmitter<any>(); // tba
  // @Output('data-invalid') dataInvalid = new EventEmitter<any>(); // tba
  MODULE_ROOT = MODULE_ROOT;

  visibleKeys: { [key: string]: boolean } = {};
  categoriesState = LoadingState.Init;

  readonly calendarIcon = faCalendarAlt;

  infoIcon = faInfoCircle;
  loadingIcon = faSpinner;
  deleteRowIcon = faTrashAlt;

  formArray = new FormArray([]);
  extradata: { [key: string]: FullExtraData } = {};
  extradataListInstances: ExtradataInstance[] = [];
  validatorMessages: { [key: string]: { [key: string]: string } } = {};
  initialValues: { [key: string]: string } = {};
  processingData: { [key: string]: boolean } = {};
  private ignoreOnUpdate: string[] = [];
  processingAnyData = true;
  private ediState$: Subscription;
  private bankdatenEdiId = 0;

  extraDataState: ExtradataInstanceReducerState;
  LoadingState = LoadingState;
  gotError = false;

  dateControls = {};
  maskDateValues = {};
  dateControlSubscriptions = [];
  today: NgbDateStruct = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};

  protected readonly deleteIcon = faTrashAlt;

  ngOnDestroy(): void {
    this.ediState$?.unsubscribe();
    for (const sub of this.dateControlSubscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.ediState$ = this.store.select(getExtradataInstanceFeatureState).subscribe(r => {
      if (r.ExtradataInstances.loadingState === LoadingState.Init) {
        this.extraDataState = r;
        return;
      }

      if (r.ExtradataInstances.loadingState !== LoadingState.Loaded || r.visibleExtradata.loadingState !== LoadingState.Loaded) {
        return;
      }

      this.extradata = r.visibleExtradata.visibleExtradata.reduce((previousValue, currentValue) => {
        previousValue[currentValue.namespace + '/' + currentValue.name] = currentValue;
        return previousValue;
      }, {} as { [key: string]: FullExtraData });

      this.extradataListInstances = [];
      this.formArray.controls = [];

      for (const ved of r.visibleExtradata.visibleExtradata) {
        const key = `${ved.namespace}/${ved.name}`;
        if (key !== 'Sanakey/Bankdaten') {
          continue;
        }

        this.visibleKeys[key] = true;
      }

      for (const vedi of r.ExtradataInstances.alleExtradataInstances) {
        const key = `${vedi.extradata.namespace}/${vedi.extradata.name}`;

        if (key !== 'Sanakey/Bankdaten') {
          continue;
        }
        this.bankdatenEdiId = vedi.id;

        for (const serverValue of vedi.value) {
          this.extradataListInstances.push(serverValue);
          const val = [
            serverValue.value,
            serverValue.value_second.value,
            serverValue.value_second.value_second.value,
            serverValue.value_second.value_second.value_second,
          ];

          const formRow = this.generateNewRow();
          this.formArray.push(formRow);
          formRow.setValue(val);
          formRow.updateValueAndValidity();
          formRow.updateValueAndValidity();
          formRow.markAsPristine();
          formRow.markAsUntouched();
        }

        this.formArray.updateValueAndValidity();
        this.formArray.markAsPristine();
        this.formArray.markAsUntouched();
      }

      this.processingAnyData = false;

      /* form set up */
      this.extraDataState = r;

      /* add empty Verband row*/
      this.addEmptyRow();
    });
  }

  addEmptyRow() {
    this.formArray.updateValueAndValidity();
    const arr = this.formArray.controls;

    let add = false;

    const addSecondEmpty = arr.length >= 1 && arr[arr.length - 1].value.reduce((a, b) => !!a || !!b, false);

    if (addSecondEmpty) {
      if (arr.length === 1) {
        add = true;
      } else {
        const v1 = arr[arr.length - 1].value.reduce((a, b) => !!a || !!b, false);
        const v2 = arr[arr.length - 2].value.reduce((a, b) => !!a || !!b, false);

        if (!v1 && !v2) {
          return;
        }
        add = true;
      }
    }
    if (!arr.length || add || (arr[arr.length - 1] as FormArray).controls[0].value) {
      arr.push(this.generateNewRow());
    }
  }

  deleteRow($event: MouseEvent, i: number) {
    const vbControl = this.formArray.controls.splice(i, 1)[0];
    vbControl.markAsDirty();
    this.formArray.updateValueAndValidity();
  }

  private generateNewRow() {
    const dateControl = new FormControlWithId();
    this.dateControls[dateControl.id] = dateControl;
    this.maskDateValues[dateControl.id] = '';
    const sub = dateControl.valueChanges.subscribe(value => {
      this.setMaskedValueFromDatepicker(dateControl);
    });
    this.dateControlSubscriptions.push(sub);

    return new FormArray([
      this.setupValidators(this.extradata['Sanakey/Bankdaten/Mit Gueltigkeit/Gueltig ab'], dateControl),
      this.setupValidators(this.extradata['Bankverbindung/Kontoinhaber'], new FormControl()),
      this.setupValidators(this.extradata['Sanakey/Bankverbindung/IBAN'], new FormControl()),
      this.setupValidators(this.extradata['Bankverbindung/BIC'], new FormControl()),
    ]);
  }

  public hasChanged() {
    let anyDirty = this.extradataListInstances.length !== (this.formArray.controls.length - 1);
    for (const row of this.formArray.controls) {
      for (const control of (row as FormArray).controls) {
        anyDirty ||= control.dirty;
      }
    }
    return anyDirty;
  }

  private disableForm() {
    for (const row of this.formArray.controls) {
      for (const control of (row as FormArray).controls) {
        control.disable();
      }
    }
  }

  private executeSpeichern(finished: (p: UpdateResult) => void) {
    // remove last
    this.formArray.controls.splice(this.formArray.controls.length - 1, 1);
    const listVal = [];
    for (const formRowControl of this.formArray.controls) {
      const formRowArray = formRowControl as FormArray;
      const gueltigAbControl = formRowArray.controls[0];
      const kontoinhaberControl = formRowArray.controls[1];
      const ibanControl = formRowArray.controls[2];
      const bicControl = formRowArray.controls[3];

      const rowVal = {
        value: {
          value: gueltigAbControl.value,
        },
        value_second: {
          value: {
            value: kontoinhaberControl.value,
          },
          value_second: {
            value: {
              value: ibanControl.value,
            },
            value_second: {
              value: bicControl.value,
            },
          },
        }
      };
      listVal.push(rowVal);
    }
    this.addEmptyRow();
    this.disableForm();
    let fn;
    if (this.bankdatenEdiId) {
      fn = this.benutzerApi.updateUserdata({id: this.bankdatenEdiId}, listVal, true);
    } else {
      fn = this.benutzerApi.createUserdata({namespace: 'Sanakey', name: 'Bankdaten'}, listVal, true);
    }
    fn.subscribe(
      () => {
        finished({
          controlName: 'Sanakey/Bankdaten',
          control: this.formArray,
          errors: [],
        });
      }, error => {
        this.gotError = true;
        this.toastService.show(
          'Bankdaten nicht aktualisiert',
          'Die Bankdaten konnten nicht gespeichert oder aktualisiert werden.',
          'danger');
        finished({
          controlName: 'Sanakey/Bankdaten',
          control: this.formArray,
          errors: ['Die Bankdaten konnten nicht aktualisiert werden'],
        });
      });
  }

  private isValid() {
    let valid = true;
    for (let i = 0; i < this.formArray.controls.length; i++) {
      const last = i === this.formArray.controls.length - 1;
      if (last) {
        continue;
      }
      const row = this.formArray.controls[i];
      const rowFormArray = row as FormArray;
      valid &&= rowFormArray.valid;
      for (const item of rowFormArray.controls) {
        valid &&= item.valid;
      }
    }
    return valid;
  }

  public speichern() {
    if (!this.hasChanged()) {
      return null;
    }
    if (!this.isValid()) {
      return null;
    }
    this.gotError = false;
    return new Promise<UpdateResult>(this.executeSpeichern.bind(this));
  }

  setValueFromMaskedDate(dateFormControl: FormControlWithId, pad = false) {
    if (!this.maskDateValues[dateFormControl.id]) {
      if (dateFormControl.value) {
        dateFormControl.setValue(null);
        dateFormControl.markAsDirty();
      }
      return;
    }

    if (this.maskDateValues[dateFormControl.id].split('.').length !== 3) {
      return;
    }

    const vals = this.maskDateValues[dateFormControl.id].split('.').reverse();

    if (pad) {
      // pad vals[1], vals[2] with leading zero if required
      if (vals[1].length < 2) {
        vals[1] = '0' + vals[1];
      }
      if (vals[2].length < 2) {
        vals[2] = '0' + vals[2];
      }
      if (vals[0].length < 2) {
        vals[0] = '200' + vals[0];
      }
      if (vals[0].length < 3) {
        vals[0] = '20' + vals[0];
      }
      if (vals[0].length < 4) {
        vals[0] = '2' + vals[0];
      }
    }

    const newValue = vals.join('-');
    if (newValue === dateFormControl.value) {
      return;
    }

    dateFormControl.setValue(newValue);
  }

  setMaskedValueFromDatepicker(dateFormControl: FormControlWithId) {
    if (!dateFormControl.value) {
      return;
    }
    const newValue = dateFormControl.value.split('-').reverse().join('.');
    if (newValue === this.maskDateValues[dateFormControl.id]) {
      return;
    }
    this.maskDateValues[dateFormControl.id] = newValue;
  }

  private setupValidators(ved: FullExtraData, control: AbstractControl<any, any>) {
    if (!ved) {
      return control;
    }
    const validators = [];
    const key = `${ved.namespace}/${ved.name}`;
    const validatorMessages: { [key: string]: string } = {};

    if (ved.required ||
      (this.forceRequired && this.forceRequired.indexOf && this.forceRequired.indexOf(key) >= 0)) {
      validators.push(Validators.required);
      validatorMessages.required = 'Dieses Feld muss ausgefüllt werden';
    }

    if (ved.type === 'email') {
      // validators.push(Validators.email);
      validatorMessages.email = 'Bitte eine gültige E-Mail Adresse angeben';
    }

    for (const serversideValidator of ved.serverside_validators) {
      const defTokens = serversideValidator.split(',');
      const clazz = defTokens[0];
      const def = defTokens.slice(1).join('');
      switch (clazz) {
        case 'Function':
          switch (def) {
            case 'IBAN Validator':
              validators.push(ibanValidator);
              validatorMessages.iban = `Die IBAN ist nicht korrekt`;
              break;
            default:
              console.warn('Function validator "%s" required', def);
          }
          break;
        case 'Regex':
          validators.push(Validators.pattern(def));
          validatorMessages.pattern = 'Das Format ist nicht gültig';
          break;
        case 'Minimum':
          validators.push(Validators.min(parseInt(def, 10)));
          validatorMessages.min = `Der Wert muss mindestens ${def} betragen`;
          break;
        case 'Maximum':
          validators.push(Validators.max(parseInt(def, 10)));
          validatorMessages.max = `Der Wert darf höchstens ${def} betragen`;
          break;
        default:
          console.error('Unbekannte ServerSide Validator Klasse: "%s" mit Definition "%s"', clazz, def);
          break;
      }
    }

    if (ved.user_creatable || ved.user_changeable) {
      control.setValidators(validators);
      this.validatorMessages[key] = validatorMessages;
    }
    return control;
  }
}
