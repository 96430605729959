import {AbstractControlOptions, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';

export class FormControlWithHints extends FormControl {
  controlFocused$ = new Subject<string>();
  controlClicked$ = new Subject<string>();
  controlSelected$ = new Subject<string>();
  // noinspection JSUnusedGlobalSymbols
  auswahl = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.controlFocused$;
    return merge(debouncedText$, inputFocus$, this.controlClicked$, this.controlSelected$).pipe(
      map(term => this.arr.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)));
    // tslint:disable-next-line:semicolon
  };

  constructor(public arr: { name: string }[],
              formState?: any,
              validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  setAuswahl(arr: { name: string }[]) {
    this.arr = arr;
  }
}

function alphabetPosition(c: string) {
  return '_abcdefghijklmnopqrstuvwxyz'.indexOf(c);
}

export function validKVNR(value: string): boolean {
  if (!value) {
    return null;
  } else if (environment.erlaubeTestEingaben && value.length === 10 && value.substring(6) === '9999') {
    return true; // Test Eingaben enden auf 9999
  } else if (value.length !== 10) {
    return false;
  }

  const firstLetterAlphabetPositionN = alphabetPosition(value[0].toLowerCase());
  const matchesRegex = /^[a-zA-Z][0-9]{9}$/.test(value);

  let firstLetterAlphabetPosition = '';

  if (firstLetterAlphabetPositionN < 10) {
    firstLetterAlphabetPosition = '0' + firstLetterAlphabetPositionN;
  } else {
    firstLetterAlphabetPosition = '' + firstLetterAlphabetPositionN;
  }

  if (firstLetterAlphabetPositionN > 0 && matchesRegex) {
    const pruefZiffer = parseInt(value[9], 10);

    const quersumme = (firstLetterAlphabetPosition + value.substr(1, 8))
      .split('')
      .reduce((previous, current, index) => {
        const produkt = (parseInt(current, 10) * (((index % 2) === 0) ? 1 : 2));

        if (produkt > 9) {
          const produktStr = produkt.toString();
          return previous + parseInt(produktStr[0], 10) + parseInt(produktStr[1], 10);
        } else {
          return previous + produkt;
        }
      }, 0);
    return pruefZiffer === (quersumme % 10);
  } else {
    return false;
  }
}

export const gueltigeKVNR: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  // if (environment.erlaubeTestEingaben) {
  //   return null;
  // }
  if (!control || !control.value) {
    return undefined;
  }
  if (!validKVNR(control.value)) {
    return {
      gueltigeKVNR: true,
    };
  }
  return null;
};


export const noFutureDate: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const d = new Date(control.value);
  if (!control || isNaN(d.getTime())) {
    return undefined;
  }
  if (d > new Date()) {
    return {
      noFutureDate: true,
    };
  }
  return null;
};


export const noDateBefore: (Date) => ValidatorFn = (date: Date) => {
  return (control: FormControl): ValidationErrors | null => {
    const d = new Date(control.value);
    if (!control || isNaN(d.getTime())) {
      return undefined;
    }
    if (d < date) {
      return {
        noDateBefore: true,
      };
    }
    return null;
  };
};


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      confirm(
        'Sie haben ungespeicherte Änderungen. Klicken Sie auf Abbrechen, um zurück ' +
        'zu den Änderungen zu gelanden. Mit OK verwerfen Sie die Änderungen.');
  }
}
