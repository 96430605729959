import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingState} from '../reducer.includes';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faOctagonCheck} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'san-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent {

  constructor(protected activeModal: NgbActiveModal) {
  }
  @Input() loadingState: LoadingState = LoadingState.Init;
  @Output() accepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected faOctagonCheck = faOctagonCheck;

  protected readonly LoadingState = LoadingState;

  clickAccept() {
    this.accepted.emit(true);
  }

  clickCancel() {
    this.accepted.emit(false);
    this.activeModal.close();
  }
}
