import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {gueltigeKVNR, noFutureDate} from '../../../shared/form-helpers';
import {ArztDatenResponse, PreviewResponse} from '../../api/client.service';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {NgbDateStruct, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-basis-daten-eingabe',
  templateUrl: './basis-daten-eingabe.component.html',
  styleUrls: ['./basis-daten-eingabe.component.scss']
})
export class BasisDatenEingabeComponent implements OnInit {

  constructor() {
  }

  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.h-100') h100 = true;

  @Input() state: ArztDatenResponse;
  @Input() baseDataForm: FormGroup;
  @Input() krankenkassen: { name: string, id: number }[];

  @Output() ready = new EventEmitter();
  @Output() zurueck = new EventEmitter();

  krankenkasse: any;

  maskDateInput = '';

  krankenkasseAuswahlConfig = {
    titleFocused$: new Subject<string>(),
    titleClicked$: new Subject<string>(),
    titleSelected$: new Subject<string>(),
    // noinspection JSUnusedGlobalSymbols
    titleAuswahl: (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.krankenkasseAuswahlConfig.titleFocused$;
      return merge(debouncedText$, inputFocus$, this.krankenkasseAuswahlConfig.titleClicked$, this.krankenkasseAuswahlConfig.titleSelected$)
        .pipe(
          map(term => this.krankenkassen.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)));
      // tslint:disable-next-line:semicolon
    }
  };

  protected readonly calendarIcon = faCalendarAlt;
  today: NgbDateStruct = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};

  formatter = (x: { name: string }) => x.name;

  ngOnInit(): void {
    this.baseDataForm.get('geburtsdatum').valueChanges.subscribe(value => {
      this.setMaskedValueFromDatepicker();
    });
  }

  checkReady() {
    for (const controlName in this.baseDataForm.controls) {
      if (!this.baseDataForm.controls.hasOwnProperty(controlName)) {
        continue;
      }
      this.baseDataForm.controls[controlName].markAsDirty();
    }
    if (this.baseDataForm.valid) {
      this.ready.emit();
    }
  }

  checkReadyOld() {
    this.baseDataForm.get('vorname').markAsDirty();
    this.baseDataForm.get('nachname').markAsDirty();
    this.baseDataForm.get('geburtsdatum').markAsDirty();
    this.baseDataForm.get('geschlecht').markAsDirty();

    const formValid = this.baseDataForm.get('vorname').valid
      && this.baseDataForm.get('nachname').valid
      && this.baseDataForm.get('geburtsdatum').valid
      && this.baseDataForm.get('geschlecht').valid;

    if (!formValid) {
      return;
    }

    this.ready.emit();
  }

  setKrankenkasse($event: NgbTypeaheadSelectItemEvent<any>) {
    this.baseDataForm.patchValue({krankenkasse: $event.item});
  }

  setValueFromMaskedDate(pad = false) {
    if (this.maskDateInput.split('.').length !== 3) {
      return;
    }

    const vals = this.maskDateInput.split('.').reverse();

    if (pad) {
      // pad vals[1], vals[2] with leading zero if required
      if (vals[1].length < 2) {
        vals[1] = '0' + vals[1];
      }
      if (vals[2].length < 2) {
        vals[2] = '0' + vals[2];
      }
    }

    const newValue = vals.join('-');
    if (newValue === this.baseDataForm.get('geburtsdatum').value) {
      return;
    }

    this.baseDataForm.patchValue({geburtsdatum: newValue});
  }

  setMaskedValueFromDatepicker() {
    const newValue = this.baseDataForm.get('geburtsdatum').value.split('-').reverse().join('.');
    if (newValue === this.maskDateInput) {
      return;
    }
    this.maskDateInput = newValue;
  }
}
