/* tslint:disable:variable-name */
import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {Store} from '@ngrx/store';
import {
  ExtradataInstanceReducerState,
  getExtradataInstanceFeatureState
} from '../../reducers/userdata.reducer';
import {
  AbstractControl,
  FormArray,
  FormControl
} from '@angular/forms';
import {LoadingState} from '../../shared/reducer.includes';
import {Subscription} from 'rxjs';
import {AppToastService} from '../../toast/app-toast.service';
import {ExtradataInstance, FullExtraData} from '../../api/benutzermanagement/models';
import {faInfoCircle, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {MODULE_ROOT} from '../root.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControlWithHints} from 'src/app/shared/form-helpers';

export class FormArrayWithEdi extends FormArray {
  public edi: ExtradataInstance;
}

export class FormControlWithEdi extends FormControl {
  public edi: ExtradataInstance;
}

class FormControlWithHintsAndEdi extends FormControlWithHints {
  public edi: ExtradataInstance;
}

interface UpdateResult {
  controlName: string;
  control: AbstractControl;
  errors?: string[];
}

@Component({
  selector: 'san-zertifizierungen',
  templateUrl: './zertifizierungen.component.html',
  styleUrls: ['./zertifizierungen.component.scss']
})
export class ZertifizierungenComponent implements OnInit, OnDestroy {
  @HostBinding('class.card') cCard = true;
  @HostBinding('class.w-100') cW100 = true;
  @HostBinding('class.mt-3') cMt3 = true;

  @Input() onlyKeys: string[] = null;
  @Input() forceRequired: string[] = null;
  @Input() speichernBtnVisible = true;

  @Output() saving = new EventEmitter<any>();
  @Output() saved = new EventEmitter<any>();
  @Output() saveError = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('data-ready') dataReady = new EventEmitter<any>();
  // @Output('data-valid') dataValid = new EventEmitter<any>(); // tba
  // @Output('data-invalid') dataInvalid = new EventEmitter<any>(); // tba
  MODULE_ROOT = MODULE_ROOT;

  visibleKeys: { [key: string]: boolean } = {};
  categoriesState = LoadingState.Init;

  infoIcon = faInfoCircle;
  loadingIcon = faSpinner;
  deleteVerbandIcon = faTrashAlt;

  zertifikateForm = new FormArray([]);
  extradata: { [key: string]: FullExtraData } = {};
  extradataZertifikatInstances: ExtradataInstance[] = [];
  validatorMessages: { [key: string]: { [key: string]: string } } = {};
  initialValues: { [key: string]: string } = {};
  processingData: { [key: string]: boolean } = {};
  private ignoreOnUpdate: string[] = [];
  processingAnyData = true;
  private ediState$: Subscription;
  private sub2$: Subscription;
  private zertifikateEdiId = 0;

  extraDataState: ExtradataInstanceReducerState;
  LoadingState = LoadingState;
  gotError = false;

  zertifikatstypen: { id: number; name: string }[] = [];

  formatter = (x: { name: string }) => x.name;

  constructor(private benutzerApi: BenutzermanagementAPIService, private store: Store,
              private toastService: AppToastService, private popupService: NgbModal) {
  }

  ngOnDestroy(): void {
    this.ediState$?.unsubscribe();
    this.sub2$?.unsubscribe();
  }

  ngOnInit(): void {
    this.ediState$ = this.store.select(getExtradataInstanceFeatureState).subscribe(r => {
      if (r.ExtradataInstances.loadingState === LoadingState.Init) {
        this.extraDataState = r;
        return;
      }

      if (r.ExtradataInstances.loadingState !== LoadingState.Loaded || r.visibleExtradata.loadingState !== LoadingState.Loaded) {
        return;
      }

      this.extradataZertifikatInstances = [];
      this.zertifikateForm.controls = [];

      for (const ved of r.visibleExtradata.visibleExtradata) {
        const key = `${ved.namespace}/${ved.name}`;
        if (key !== 'Sanakey/Zertifikate') {
          continue;
        }

        this.visibleKeys[key] = true;
      }

      for (const vedi of r.ExtradataInstances.alleExtradataInstances) {
        const key = `${vedi.extradata.namespace}/${vedi.extradata.name}`;

        if (key !== 'Sanakey/Zertifikate') {
          continue;
        }
        this.zertifikateEdiId = vedi.id;

        for (const zEntry of vedi.value) {
          this.extradataZertifikatInstances.push(zEntry);
          // tuple (typ, tuple (file, hinweis))
          const val = [
            {name: zEntry.value.value}, // typ
            [
              null, // overwrite file
              zEntry.value_second?.value_second || '', // hinweis
              zEntry.value_second?.value?.id || null, // file from server
            ],
          ];

          const formRow = this.generateNewZertifikatRow();
          this.zertifikateForm.push(formRow);
          formRow.setValue(val);
          formRow.updateValueAndValidity();
          formRow.updateValueAndValidity();
          formRow.markAsPristine();
          formRow.markAsUntouched();
        }

        this.zertifikateForm.updateValueAndValidity();
        this.zertifikateForm.markAsPristine();
        this.zertifikateForm.markAsUntouched();
      }

      this.processingAnyData = false;

      /* form set up */
      this.extraDataState = r;

      /* add empty Verband row*/
      this.addEmptyZertifikatRow();
    });

    this.benutzerApi.fetchEnum('Sanakey', 'Zertifikate/Zertifikatstyp').subscribe(
      (next) => {
        if (next.results.length < 1) {
          this.toastService.show('Verbindung fehlerhaft',
            `Die Liste der ${'Sanakey/Zertifikate/Zertifikatstyp'} konnte nicht geladen werden.`,
            'danger');
        }
        for (const choice of next.results[0].enum.choices) {
          this.zertifikatstypen.push(choice);
        }
      },
      () => {
        this.toastService.show('Verbindung fehlgeschlagen',
          `Die Liste der ${'Sanakey/Zertifikate/Zertifikatstyp'} konnte nicht geladen werden (Verbindungsfehler).`,
          'danger');
      });
  }

  /*
   * Zertifikate
   * */
  addEmptyZertifikatRow(addSecondEmpty = false) {
    this.zertifikateForm.updateValueAndValidity();
    const arr = this.zertifikateForm.controls;

    let add = false;
    if (addSecondEmpty) {
      if (arr.length === 1) {
        add = true;
      } else {
        const v1 = (arr[arr.length - 1] as FormArray).controls[0].value;
        const v2 = (arr[arr.length - 2] as FormArray).controls[0].value;

        if (!v1 && !v2) {
          return;
        }
        add = true;
      }
    }
    if (!arr.length || add || (arr[arr.length - 1] as FormArray).controls[0].value) {
      arr.push(this.generateNewZertifikatRow());
    }
  }

  deleteZertifikatEintrag($event: MouseEvent, i: number) {
    const vbControl = this.zertifikateForm.controls.splice(i, 1)[0];
    vbControl.markAsDirty();
    this.zertifikateForm.updateValueAndValidity();
  }

  private generateNewZertifikatRow() {
    return new FormArrayWithEdi([
      new FormControlWithHintsAndEdi(this.zertifikatstypen, ''),  // zert typ
      new FormArray([
        new FormControl(), // zert file
        new FormControl(), // zert hinweis
        new FormControl(), // zert file from server (only filename or sth like that)
      ]),
    ]);
  }

  private istBekanntesZertifikat(name: string) {
    return this.zertifikatstypen.find((value => value.name === name));
  }

  setFileValue(control: any, $event: Event) {
    const files = [];
    for (const oFile of ($event.target as any).files) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof event.target.result === 'string') {
          files.push(event.target.result);
        }
      };
      reader.readAsDataURL(oFile);
    }

    control.setValue(files);
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  downloadZertifikat(i: number) {
    this.benutzerApi.downloadEDFile(this.extradataZertifikatInstances[i].value_second.value.id).subscribe(value => {
      if (!value.b64data) {
        this.toastService.show('Ein Fehler ist aufgetreten',
          'Das Zertifikat konnte nicht heruntergeladen werden. Die Datei konnte nicht abgerufen werden. Bitte versuchen Sie es später erneut.',
          'danger');
        return;
      }
      this.downloadData('data:application/pdf;base64,' + value.b64data,
        (this.zertifikateForm.controls[i] as FormArray).controls[0].value.name + '.pdf');
    }, error => {
      this.toastService.show('Ein Fehler ist aufgetreten',
        'Das Zertifikat konnte nicht heruntergeladen werden. Bitte versuchen Sie es später erneut.',
        'danger');
    });
  }

  private downloadData(pdf_b64: string, filename: string) {
    const link = document.createElement('a');
    link.href = pdf_b64;
    link.download = filename;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      link.remove();
    }, 100);
  }

  public hasChanged() {
    let anyDirty = this.extradataZertifikatInstances.length !== (this.zertifikateForm.controls.length - 1);
    for (const zertRow of this.zertifikateForm.controls) {
      const zertRowFA = zertRow as FormArray;
      const zertTypControl = zertRowFA.controls[0];
      const zertFileControl = (zertRowFA.controls[1] as FormArray).controls[0];
      const zertBemerkungenControl = (zertRowFA.controls[1] as FormArray).controls[1];
      anyDirty ||= zertTypControl.dirty || zertFileControl.dirty || zertBemerkungenControl.dirty;
    }
    return anyDirty;
  }

  private disableForm() {
    for (const zertRow of this.zertifikateForm.controls) {
      const zertRowFA = zertRow as FormArray;
      const zertTypControl = zertRowFA.controls[0];
      const zertFileControl = (zertRowFA.controls[1] as FormArray).controls[0];
      const zertBemerkungenControl = (zertRowFA.controls[1] as FormArray).controls[1];
      zertTypControl.disable();
      zertFileControl.disable();
      zertBemerkungenControl.disable();
    }
  }

  private executeSpeichern(finished: (p: UpdateResult) => void) {
    // remove last
    this.zertifikateForm.controls.splice(this.zertifikateForm.controls.length - 1, 1);
    const listVal = [];
    for (const zertRow of this.zertifikateForm.controls) {
      const zertRowFA = zertRow as FormArray;
      const zertTypControl = zertRowFA.controls[0];
      const zertFileControl = (zertRowFA.controls[1] as FormArray).controls[0];
      const zertServerFileControl = (zertRowFA.controls[1] as FormArray).controls[2];
      const zertBemerkungenControl = (zertRowFA.controls[1] as FormArray).controls[1];
      let typ_value;
      let typ_alternative_value;
      const zertTypName = zertTypControl.value.name;
      if (this.istBekanntesZertifikat(zertTypName)) {
        typ_value = zertTypName;
      } else {
        typ_alternative_value = zertTypName;
      }

      let fileValue;
      // 3 optionen:
      // - alte datei bleibt bestehen
      // - neue datei überschreibt alte datei
      // - es wurde keine datei angegeben
      // -> löschen nur über extra button

      if (zertFileControl?.value?.length) {
        // neue datei überschreibt alte oder neue anlage
        fileValue = zertFileControl.value[0];
      } else if (!zertFileControl?.value?.length && zertServerFileControl.value) {
        // alte datei bleibt bestehen
        fileValue = zertServerFileControl.value;
      } else {
        // keine datei angegeben
        fileValue = '__DO_NOT_CHANGE__';
      }

      const rowVal = {
        value: {
          value: typ_value,
          alternative_value: typ_alternative_value,
        },
        value_second: {
          value: {
            value: fileValue,
          },
          value_second: {
            value: zertBemerkungenControl.value,
          },
        },
      };
      listVal.push(rowVal);
    }
    this.addEmptyZertifikatRow();
    this.disableForm();
    let fn;
    if (this.zertifikateEdiId) {
      fn = this.benutzerApi.updateUserdata({id: this.zertifikateEdiId}, listVal, true);
    } else {
      fn = this.benutzerApi.createUserdata({namespace: 'Sanakey', name: 'Zertifikate'}, listVal, true);
    }
    fn.subscribe(
      () => {
        finished({
          controlName: 'Sanakey/Zertifikate',
          control: this.zertifikateForm,
          errors: [],
        });
      }, error => {
        this.gotError = true;
        this.toastService.show(
          'Zertifikate nicht aktualisiert',
          'Die Zertifikate konnten nicht gespeichert oder aktualisiert werden.',
          'danger');
        finished({
          controlName: 'Sanakey/Zertifikate',
          control: this.zertifikateForm,
          errors: ['Die Zertifikate konnten nicht aktualisiert werden'],
        });
      });
  }

  private isValid() {
    let valid = true;
    for (let i = 0; i < this.zertifikateForm.controls.length; i++) {
      const last = i === this.zertifikateForm.controls.length - 1;
      if (last) {
        continue;
      }
      const zertRow = this.zertifikateForm.controls[i];
      const zertRowFA = zertRow as FormArray;
      const zertTypControl = zertRowFA.controls[0];
      const zertFileControl = (zertRowFA.controls[1] as FormArray).controls[0];
      // let zertServerFileControl = (zertRowFA.controls[1] as FormArray).controls[2];
      const zertBemerkungenControl = (zertRowFA.controls[1] as FormArray).controls[1];
      const zertTypName = zertTypControl.value.name;
      valid &&= zertRowFA.valid && zertFileControl.valid && zertBemerkungenControl.valid && zertTypName;
    }
    return valid;
  }

  public speichern() {
    if (!this.hasChanged()) {
      return null;
    }
    if (!this.isValid()) {
      return null;
    }
    this.gotError = false;
    return new Promise<UpdateResult>(this.executeSpeichern.bind(this));
  }
}
