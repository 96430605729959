import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StepperComponent} from './stepper/stepper.component';
import {StepComponent} from './stepper/step/step.component';
import {FormInputErrorComponent} from './form-input-error/form-input-error.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LogosComponent} from './logos/logos.component';
import {HoverDirective} from './hover.directive';
import {AsFunctionPipe, CastPipe, TneStatusPipe} from './pipes/as-function.pipe';
import {DatumPlusDeltaPipe} from './pipes/datum-plus-delta.pipe';
import {ToDatePipe} from './pipes/to-date.pipe';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {DifaScienceTextComponent} from './difa-science-text/difa-science-text.component';
import {OnkogisterZuUrogisterVertragNamenPipe} from './pipes/onkogister-zu-urogister-vertrag-namen.pipe';
import {VisitenkarteComponent} from './visitenkarte/visitenkarte.component';
import {NgxVcardModule} from 'ngx-vcard';
import {PendingChangesGuard} from './form-helpers';
import {TooltipComponent} from './tooltip/tooltip.component';
import {NgbCollapse, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { BlockingLoaderComponent } from './blocking-loader/blocking-loader.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { DownloadModalComponent } from './download-modal/download-modal.component';


@NgModule({
    declarations: [
        StepComponent,
        StepperComponent,
        FormInputErrorComponent,
        LogosComponent,
        HoverDirective,
        AsFunctionPipe,
        TneStatusPipe,
        DatumPlusDeltaPipe,
        ToDatePipe,
        DifaScienceTextComponent,
        OnkogisterZuUrogisterVertragNamenPipe,
        VisitenkarteComponent,
        TooltipComponent,
        CastPipe,
        BlockingLoaderComponent,
        BackButtonComponent,
        DownloadModalComponent,
    ],
  exports: [
    CommonModule,
    StepperComponent,
    StepComponent,
    FormInputErrorComponent,
    LogosComponent,
    HoverDirective,
    AsFunctionPipe,
    TneStatusPipe,
    DatumPlusDeltaPipe,
    ToDatePipe,
    DifaScienceTextComponent,
    OnkogisterZuUrogisterVertragNamenPipe,
    VisitenkarteComponent,
    TooltipComponent,
    CastPipe,
    BlockingLoaderComponent,
    BackButtonComponent,
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgScrollbarModule,
        NgxVcardModule,
        NgbTooltipModule,
        NgbCollapse,
    ],
    providers: [
        PendingChangesGuard,
    ]
})
export class SharedModule {
}
