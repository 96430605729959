import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EinschreibungComponent} from './einschreibung/einschreibung.component';
import {PinComponent} from './einschreibung/pin/pin.component';
import {ZustimmungComponent} from './einschreibung/zustimmung/zustimmung.component';
import {WiderrufComponent} from './einschreibung/widerruf/widerruf.component';
import {
  ZustimmungBestaetigungComponent
} from './einschreibung/zustimmung-bestaetigung/zustimmung-bestaetigung.component';
import {WiderrufBestaetigungComponent} from './einschreibung/widerruf-bestaetigung/widerruf-bestaetigung.component';
import {PartnerEinschreibungComponent} from './partner-einschreibung/partner-einschreibung.component';
import {InfoComponent} from './einschreibung/info/info.component';
import {RouterModule, Routes} from '@angular/router';
import {Store} from '@ngrx/store';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SharedModule} from '../shared/shared.module';
import {CodeInputModule} from 'angular-code-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MomentModule} from 'ngx-moment';
import {InfoPartnerComponent} from './partner-einschreibung/info-partner/info-partner.component';
import {PinPartnerComponent} from './partner-einschreibung/pin-partner/pin-partner.component';
import {WiderrufPartnerComponent} from './partner-einschreibung/widerruf-partner/widerruf-partner.component';
import {
  WiderrufBestaetigungPartnerComponent
} from './partner-einschreibung/widerruf-bestaetigung-partner/widerruf-bestaetigung-partner.component';
import {
  ZustimmungBestaetigungPartnerComponent
} from './partner-einschreibung/zustimmung-bestaetigung-partner/zustimmung-bestaetigung-partner.component';
import {ZustimmungPartnerComponent} from './partner-einschreibung/zustimmung-partner/zustimmung-partner.component';
import {QRTNEDatenEingabeComponent} from './qrtnedaten-eingabe/qrtnedaten-eingabe.component';
import {BasisDatenEingabeComponent} from './qrtnedaten-eingabe/basis-daten-eingabe/basis-daten-eingabe.component';
import {AddressDatenEingabeComponent} from './qrtnedaten-eingabe/address-daten-eingabe/address-daten-eingabe.component';
import {WillkommenComponent} from './qrtnedaten-eingabe/willkommen/willkommen.component';
import {InfoQRTNEComponent} from './qrtnedaten-eingabe/info-qrtne/info-qrtne.component';
import {
  InfotextArztdatenQRTNEComponent
} from './qrtnedaten-eingabe/infotext-arztdaten-qrtne/infotext-arztdaten-qrtne.component';
import {
  ZustimmungDigiTneZweiComponent
} from './qrtnedaten-eingabe/zustimmung-digi-tne-zwei/zustimmung-digi-tne-zwei.component';
import {LoginComponent} from './qrtnedaten-eingabe/login/login.component';
import {
  ZustimmungBestaetigungDigiTneZweiComponent
} from './qrtnedaten-eingabe/zustimmung-bestaetigung-digi-tne-zwei/zustimmung-bestaetigung-digi-tne-zwei.component';
import {
  FehlerArztNichtEingeschriebenComponent
} from './qrtnedaten-eingabe/fehler-arzt-nicht-eingeschrieben/fehler-arzt-nicht-eingeschrieben.component';
import {TNZEingabeComponent} from './qrtnedaten-eingabe/tnzeingabe/tnzeingabe.component';
import {PendingChangesGuard} from '../shared/form-helpers';
import {
  BasisDatenEingabeZweiComponent
} from './qrtnedaten-eingabe/basis-daten-eingabe-zwei/basis-daten-eingabe-zwei.component';
import {NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from 'ngx-mask';

const routes: Routes = [
  {
    path: 'patientenselbsteinschreibung/digitne2.0',
    component: QRTNEDatenEingabeComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'patientenselbsteinschreibung',
    component: EinschreibungComponent
  },
  {
    path: 'partnerselbsteinschreibung',
    component: PartnerEinschreibungComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [
    provideEnvironmentNgxMask(),
  ]
})
export class PatientenSelbstEinschreibungRoutingModule {
  constructor(private store: Store) {
  }
}

@NgModule({
  declarations: [EinschreibungComponent,
    /* Patienten Komponenten */
    InfoComponent,
    PinComponent,
    ZustimmungComponent,
    ZustimmungBestaetigungComponent,
    WiderrufComponent,
    WiderrufBestaetigungComponent,
    /* Partner Komponenten */
    PartnerEinschreibungComponent,
    InfoPartnerComponent,
    PinPartnerComponent,
    ZustimmungPartnerComponent,
    ZustimmungBestaetigungPartnerComponent,
    WiderrufPartnerComponent,
    WiderrufBestaetigungPartnerComponent,
    /* QR TNE (Digi TNE 2.0) */
    QRTNEDatenEingabeComponent,
    BasisDatenEingabeComponent,
    BasisDatenEingabeZweiComponent,
    AddressDatenEingabeComponent,
    WillkommenComponent,
    InfoQRTNEComponent,
    InfotextArztdatenQRTNEComponent,
    ZustimmungDigiTneZweiComponent,
    LoginComponent,
    ZustimmungBestaetigungDigiTneZweiComponent,
    FehlerArztNichtEingeschriebenComponent,
    TNZEingabeComponent,
  ],
  imports: [
    CommonModule,
    PatientenSelbstEinschreibungRoutingModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
    CodeInputModule,
    FormsModule,
    NgbModule,
    MomentModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ]
})
export class PatientenSelbstEinschreibungModule {
}
