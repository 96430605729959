<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="modalSplittingBearbeiten">Abrechnungsdaten exportieren</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"
            (click)="clickCancel()"></button>
  </div>
  <ng-container *ngIf="loadingState === LoadingState.Init">
  <div class="modal-body">
    <p>
      Achtung, der Download enthält sensible Daten und Informationen. Bitte speichern Sie diesen an einem sicheren Ort.
    </p>
  </div>
  <div class="modal-footer d-flex flex-row align-content-between">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            (click)="clickAccept()">
      OK, verstanden
    </button>
  </div>
  </ng-container>
  <ng-container *ngIf="loadingState !== LoadingState.Init">
    <div *ngIf="loadingState === LoadingState.Loading" class="d-flex flex-column justify-content-center align-items-center p-5">
      <div class="logo-container mt-5 mb-5">
        <img class="static-logo"
             src="assets/logos/Sanakey%20Symbol%20Logo_blau.png"
             alt="Sanakey Logo Static">
        <img class="animated_logo"
             src="assets/logos/Sanakey%20Symbol%20Logo_blau.png"
             alt="Sanakey Logo Animated">
      </div>
      <span>
        Die Datei wird erstellt ...
      </span>
    </div>
    <div *ngIf="loadingState === LoadingState.Loaded" class="d-flex flex-column justify-content-center align-items-center p-5">
      <div class="logo-container mt-5 mb-5">
        <fa-icon class="static-logo color-success" [icon]="faOctagonCheck" size="10x"></fa-icon>
      </div>
      <span>
        Erledigt! Download startet
      </span>
    </div>
  </ng-container>
</div>
