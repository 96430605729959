<ng-template let-control="control" #requiredStar>
  <span *ngIf="(control?.validator | asFunction)({})?.required">*</span>
</ng-template>

<ng-template #kategorieHeader let-title="title">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="form-group col-12">
          <h6 class="card-title text-uppercase">{{ title }}</h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textInput let-formControl="formControl" let-key="key">
  <div class="col-12 col-lg-3 form-group">
    <label>
      <span>{{ extradata[key]?.display_field_name }}</span>
      <ng-container
        *ngTemplateOutlet="requiredStar; context:{control: formControl}"></ng-container>
      <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
               [title]="extradata[key]?.display_help"></fa-icon>
    </label>

    <div class="position-relative">
      <input type="text" class="form-control" [formControl]="formControl"
             (change)="addEmptyRow()"
             (keyup)="addEmptyRow()"/>
      <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                    <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                  </span>
    </div>
    <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
      <san-form-input-error [message]="$any(k.value)"
                            [visible]="formControl.hasError($any(k.key))"></san-form-input-error>
    </div>
  </div>
</ng-template>

<ng-template #gueltigAb let-formControl="formControl" let-key="key">
  <div class="col-10 col-lg-2 form-group">
    <label>
      <span>{{ extradata[key]?.display_field_name }}</span>
      <ng-container
        *ngTemplateOutlet="requiredStar; context:{control: formControl}"></ng-container>
      <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
               [title]="extradata[key]?.display_help"></fa-icon>
    </label>
    <div class="position-relative">
      <div class="input-group">
        <input class="form-control"
               placeholder="TT.MM.JJJJ"
               type="text"
               aria-label="Gültig ab"
               mask="d0.M0.0000"
               (focusout)="setValueFromMaskedDate(formControl, true)"
               [dropSpecialCharacters]="false"
               [ngModelOptions]="{standalone: true}"
               [(ngModel)]="maskDateValues[formControl.id]"
               (change)="addEmptyRow()"
               (keyup)="addEmptyRow()"
               (ngModelChange)="setValueFromMaskedDate(formControl)"
               autocomplete="off">
        <input ngbDatepicker
               [minDate]="today"
               #dp="ngbDatepicker"
               class="form-control fake-input"
               placeholder="TT.MM.JJJJ"
               (change)="addEmptyRow()"
               (keyup)="addEmptyRow()"
               aria-label="Gültig ab"
               tabindex="5"
               [formControl]="formControl"
               autocomplete="off">
        <span class="btn btn-outline-primary" (click)="dp.toggle()">
           <fa-icon [icon]="calendarIcon"></fa-icon>
        </span>
      </div>
      <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
    </div>
    <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
      <san-form-input-error [message]="$any(k.value)"
                            [visible]="formControl.hasError($any(k.key))"></san-form-input-error>
    </div>
  </div>
</ng-template>

<div class="card-body">
  <ng-container *ngTemplateOutlet="kategorieHeader; context:{title: 'Bankdaten'}"></ng-container>
  <div class="row">
    <div [@vis] class="col-12" *ngIf="!($any(extradata)|keyvalue).length"><p>wird geladen ...</p></div>
    <div [@vis] class="col-12" *ngIf="($any(extradata)|keyvalue).length">
      <div class="row remove-last-hr"
           *ngFor="let vm of $any(formArray.controls); index as i; count as c">
        <!-- FormControls: Gültig ab, Kontoinhaber, IBAN, BIC -->
        <ng-container
          *ngTemplateOutlet="textInput; context:{formControl: vm.controls[1], key: 'Sanakey/Bankverbindung/Kontoinhaber'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="textInput; context:{formControl: vm.controls[2], key: 'Sanakey/Bankverbindung/IBAN'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="textInput; context:{formControl: vm.controls[3], key: 'Sanakey/Bankverbindung/BIC'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="gueltigAb; context:{formControl: vm.controls[0], key: 'Sanakey/Bankdaten/Mit Gueltigkeit/Gueltig ab'}"></ng-container>
        <div class="form-group col-2 col-lg-1 d-flex align-items-end justify-content-end">
          <div class="float-right">
            <a class="btn btn-outline-warning"
               [class.disabled]="i == c - 1"
               (click)="deleteRow($event, i)">
              <fa-icon [icon]="deleteIcon"></fa-icon>
            </a>
          </div>
        </div>
        <div class="form-group col-12">
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
