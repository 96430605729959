<ng-template let-control="control" #requiredStar>
  <span *ngIf="(control?.validator | asFunction)({})?.required">*</span>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t" class="text-wrap"></ngb-highlight>
</ng-template>

<ng-template #kategorieHeader let-title="title">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="form-group col-12">
          <h6 class="card-title text-uppercase">{{ title }}</h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="card-body">
  <ng-container *ngTemplateOutlet="kategorieHeader; context:{title: 'Zertifizierungen und Nachweise'}"></ng-container>
  <div class="row">
    <ng-container *ngIf="'Sanakey/Zertifikate'; let key">
      <div class="col-12 form-group" *ngIf="visibleKeys[key]">
        <div class="position-relative">
          <div class="col-12 row remove-last-hr"
               *ngFor="let vm of $any(zertifikateForm.controls); index as i">
            <div class="col-5 col-sm-6 d-flex align-items-end">
              <div class="form-group w-100">
                <label class="">Zertfikatstyp:</label>
                <div class="position-relative">
                  <input
                    #vn
                    type="text"
                    class="form-control"
                    [formControl]="vm.controls[0]"
                    autocomplete="off"
                    [ngbTypeahead]="$any(vm.controls[0]).auswahl"
                    [resultTemplate]="rt"
                    [inputFormatter]="formatter"
                    (focus)="$any(vm.controls[0]).controlFocused$.next($any($event).target.name)"
                    (selectItem)="addEmptyZertifikatRow(!vn.value)"
                    (change)="addEmptyZertifikatRow(!vn.value)"
                    (keyup)="addEmptyZertifikatRow(!vn.value)"
                    container="form"
                  />
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                      <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                    </span>
                </div>
                <san-form-input-error message="Bitte einen Eintrag aus der Liste wählen"
                                      [visible]="vm.controls[0].dirty && !vm.controls[0].value.name"></san-form-input-error>
              </div>
            </div>
            <div class="col-5">
              <div class="form-group">
                <label>Zertifikat (Datei):</label>
                <div class="position-relative">
                  <input [hidden]="true" type="file" #thefile
                         (change)="setFileValue(vm.controls[1].controls[0], $event)"
                         accept="application/pdf">
                  <div class="btn-group">
                    <button *ngIf="!vm.controls[1].controls[2].value" class="btn btn-primary" (click)="thefile.click()">
                      <span *ngIf="vm.controls[1].controls[0].value">Datei ausgewählt</span>
                      <span *ngIf="!vm.controls[1].controls[0].value">Datei wählen</span>
                    </button>
                    <button *ngIf="vm.controls[1].controls[2].value" class="btn btn-warning" (click)="thefile.click()">
                      <span *ngIf="vm.controls[1].controls[0].value">Datei ausgewählt</span>
                      <span *ngIf="!vm.controls[1].controls[0].value">Datei ändern</span>
                    </button>
                    <button *ngIf="vm.controls[1].controls[2].value" class="btn btn-primary"
                            (click)="downloadZertifikat(i)">Datei herunterladen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-1 d-flex align-items-end justify-content-end">
              <div class="float-right form-group">
                <a class="btn btn-outline-warning"
                   [class.disabled]="!vn.value"
                   (click)="deleteZertifikatEintrag($event, i)">
                  <fa-icon [icon]="deleteVerbandIcon"></fa-icon>
                </a>
              </div>
            </div>
            <div class="col-12">
              <label class="">Bemerkungen:</label>
              <textarea class="form-control"
                        [formControl]="vm.controls[1].controls[1]"
                        placeholder="Bemerkungen und Hinweise (optional)"></textarea>
            </div>
            <div class="col-12">
              <hr>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <san-form-input-error message="Die Zertifikate konnten nicht aktualisiert werden"
                        [visible]="gotError"></san-form-input-error>
</div>
