<div class="container h-100">
  <div #top></div>
  <ngb-accordion class="m_accordion mb-3" #acc="ngbAccordion" [closeOthers]="true">
    <ngb-panel *ngIf="state?.text_teilnahmeerklaerung">
      <ng-template ngbPanelTitle>
        <span>Teilnahmeerklärung <br class="d-md-none"><small>(Klick zum anzeigen/verbergen)</small></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div [innerHTML]="state.text_teilnahmeerklaerung"></div>
        <a href="javascript:void(0)" (click)="top.scrollIntoView({behavior: 'smooth'})">nach oben</a>
        &nbsp;|&nbsp;
        <a href="javascript:void(0)" (click)="acc.collapseAll()">schließen</a>
      </ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="state?.text_bedingungen">
      <ng-template ngbPanelTitle>
        <span>Patienteninformationen <br class="d-md-none"><small>(Klick zum anzeigen/verbergen)</small></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div [innerHTML]="state.text_bedingungen"></div>
        <a href="javascript:void(0)" (click)="top.scrollIntoView({behavior: 'smooth'})">nach oben</a>
        &nbsp;|&nbsp;
        <a href="javascript:void(0)" (click)="acc.collapseAll()">schließen</a>
      </ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="state?.text_datenschutz">
      <ng-template ngbPanelTitle>
        <span>Datenschutzinformationen <br class="d-md-none"><small>(Klick zum anzeigen/verbergen)</small></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div [innerHTML]="state.text_datenschutz"></div>
        <a href="javascript:void(0)" (click)="top.scrollIntoView({behavior: 'smooth'})">nach oben</a>
        &nbsp;|&nbsp;
        <a href="javascript:void(0)" (click)="acc.collapseAll()">schließen</a>
      </ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="state?.text_therapieinhalte">
      <ng-template ngbPanelTitle>
        <span>Weitere Therapieinformationen <br class="d-md-none"><small>(Klick zum anzeigen/verbergen)</small></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div [innerHTML]="state.text_therapieinhalte"></div>
        <a href="javascript:void(0)" (click)="top.scrollIntoView({behavior: 'smooth'})">nach oben</a>
        &nbsp;|&nbsp;
        <a href="javascript:void(0)" (click)="acc.collapseAll()">schließen</a>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <div class="row row-cols-1">
    <div class="col">
      <h5>Zusammenfassung Ihrer Daten</h5>
    </div>
  </div>
  <!-- mobile view -->
  <div class="row row-cols-1 d-md-none">
    <div class="col">
      <div class="row">
        <label>
          Untenstehend sehen Sie die Daten Ihres behandelnden Arztes
        </label>
      </div>
      <div class="form-group">
        <label class="small">LANR / BSNR</label>
        <div>{{ state?.arzt?.lanr }} / {{ state?.arzt?.bsnr }}</div>
      </div>
      <div class="form-group">
        <label class="small">Behandelnder Arzt</label>
        <div>{{ state?.arzt?.anrede_titel_nachname }}</div>
      </div>
      <div class="form-group">
        <label class="small">Anschrift</label>
        <div>{{ state?.arzt?.adresse }}</div>
      </div>
      <div class="form-group">
        <label class="small">Telefon</label>
        <div>{{ state?.arzt?.telefonnummer }}</div>
      </div>
    </div>
    <div class="col">
      <hr>
      <div class="row">
        <label>Hier sehen Sie Ihre vom Arzt erfassten Daten. Bitte prüfen Sie, ob diese korrekt sind</label>
      </div>
      <div class="form-group">
        <label class="small">Krankenkasse</label>
        <div>{{ state?.krankenkasse.name }}</div>
      </div>
      <div class="form-group">
        <label class="small">Versichertennummer</label>
        <div>{{ state?.patient.versicherten_nummer }}</div>
      </div>
      <div class="form-group">
        <label class="small">Name</label>
        <div>{{ state?.patient.nachname }}, {{ state?.patient.vorname }}</div>
      </div>
      <div class="form-group">
        <label class="small">Anschrift</label>
        <div>{{ state?.patient.strasse }} {{ state?.patient.hausnummer }}
          , {{ state?.patient.plz }} {{ state?.patient.ort }}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="text-center w-100">
          <input id="cbxm1" type="checkbox" [disabled]="sending" [(ngModel)]="bed1">
        </div>
        <label for="cbxm1">
          Ja, ich habe die Erläuterungen zur Teilnahme und die Patienteninformation
          erhalten und möchte gemäß den dortigen Ausführungen das Angebot der Besonderen Versorgung nutzen und bestätige
          dies hiermit.
        </label>
      </div>
      <div class="row">
        <div class="text-center w-100">
          <input id="cbxm2" type="checkbox" [disabled]="sending" [(ngModel)]="bed2">
        </div>
        <label for="cbxm2">
          Ja, ich habe die Erläuterungen zur Einwilligung in die Datenverarbeitung und -nutzung erhalten
          und bin mit der beschriebenen Datenerhebung, -verarbeitung und -nutzung im Rahmen meiner Teilnahme
          an der Besonderen Versorgung einverstanden und bestätige dies hiermit.
        </label>
      </div>
      <div class="row">
        <div class="text-center w-100">
          <input id="cbxm3" type="checkbox" [disabled]="sending" [(ngModel)]="zustimmungEvaluation">
        </div>
        <label for="cbxm3">
          Ich willige ein, dass meine Daten zu Forschungszwecken anonymisiert werden können und meine anonymisierten
          Daten, die mir nicht mehr zugeordnet werden können, auch nach dem Widerruf der Einwilligung verarbeitet werden
          können.
        </label>
      </div>
      <hr>
    </div>
  </div>
  <!-- desktop view -->
  <div class="row row-cols-1 d-none d-md-block">
    <div class="col">
      <table class="table table-borderless data-table table-sm">
        <tr>
          <td colspan="2">Untenstehend sehen Sie die Daten Ihres behandelnden Arztes</td>
          <td colspan="2">Hier sehen Sie Ihre vom Arzt erfassten Daten. Bitte prüfen Sie, ob diese korrekt sind</td>
        </tr>
        <tr>
          <td colspan="2">
            <hr class="my-0">
          </td>
          <td colspan="2">
            <hr class="my-0">
          </td>
        </tr>
        <tr>
          <td>LANR / BSNR</td>
          <td>{{ state?.arzt?.lanr }} / {{ state?.arzt?.bsnr }}</td>
          <td>Krankenkasse</td>
          <td>{{ state?.krankenkasse.name }}</td>
        </tr>
        <tr>
          <td>Behandelnder Arzt</td>
          <td>{{ state?.arzt?.anrede_titel_nachname }}</td>
          <td>Versichertennummer</td>
          <td>{{ state?.patient.versicherten_nummer }}</td>
        </tr>
        <tr>
          <td>Anschrift</td>
          <td>{{ state?.arzt?.adresse }}</td>
          <td>Name</td>
          <td>{{ state?.patient.nachname }}, {{ state?.patient.vorname }}</td>
        </tr>
        <tr>
          <td>Telefon</td>
          <td>{{ state?.arzt?.telefonnummer }}</td>
          <td>Anschrift</td>
          <td>{{ state?.patient.strasse }} {{ state?.patient.hausnummer }}
            , {{ state?.patient.plz }} {{ state?.patient.ort }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <hr class="my-0">
          </td>
          <td colspan="2">
            <hr class="my-0">
          </td>
        </tr>
      </table>
      <div class="row">
        <table class="table table-borderless">
          <tr>
            <td>
              <input id="cbx1" type="checkbox" [disabled]="sending" [(ngModel)]="bed1">
            </td>
            <td>
              <label for="cbx1">
                Ja, ich habe die Erläuterungen zur Teilnahme und die Patienteninformation
                erhalten und möchte gemäß den dortigen Ausführungen das Angebot der Besonderen Versorgung nutzen und
                bestätige
                dies hiermit.
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <input id="cbx2" type="checkbox" [disabled]="sending" [(ngModel)]="bed2">
            </td>
            <td>
              <label for="cbx2">
                Ja, ich habe die Erläuterungen zur Einwilligung in die Datenverarbeitung und -nutzung erhalten
                und bin mit der beschriebenen Datenerhebung, -verarbeitung und -nutzung im Rahmen meiner Teilnahme
                an der Besonderen Versorgung einverstanden und bestätige dies hiermit.
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <input id="cbx3" type="checkbox" [disabled]="sending" [(ngModel)]="zustimmungEvaluation">
            </td>
            <td>
              <label for="cbx3">
                Ich willige ein, dass meine Daten zu Forschungszwecken anonymisiert werden können und meine anonymisierten
                Daten, die mir nicht mehr zugeordnet werden können, auch nach dem Widerruf der Einwilligung verarbeitet werden
                können.
              </label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!-- both -->
  <div class="text-center">
    <button #btnBestaetigen class="btn btn-primary" [disabled]="!bed1 || !bed2 || sending"
            (click)="onBestaetigenClicked()">
      Bestätigen<span *ngIf="sending">&nbsp;<fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span>
    </button>
  </div>
</div>
