<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 h-100">
      <div class="shrink-top-animated h-100" [class.out]="loading">
        <div class="text-center my-5">
          <fa-icon class="" [icon]="spinnerIcon" animation="spin-pulse" size="3x"></fa-icon>
          <div class="">Die Ansicht wird geladen ...</div>
        </div>
      </div>
      <div class="shrink-top-animated p-0 p-md-3 h-100" [class.out]="!loading">
        <san-info-qrtne *ngIf="(!userParams.versorgungsVertragID && !userParams.vertragsgruppe) ||
         !userParams.uuid; else valid"></san-info-qrtne>
        <ng-template #valid>
          <!--              1. QR Code Scannen (oder Link öffnen)-->
          <!--              2. Daten angeben-->
          <!--              2.1 KVNR, Geb.Dat., Name usw, Email-->
          <!--              2.2 Optional Adresse TBD-->
          <!--              3. Zustimmung geben (Checkboxen aktivieren)-->
          <!--              4. Email mit Bestätigung und Links empfangen (für Widerruf oder Abruf der TNE PDF)-->
          <!--              4.1. Klick auf Link => Eingabe KVNR, Geb.Dat.-->
          <!--              4.2 persönliche TNE Seite zum TNE Download oder Widerruf öffnet sich-->
          <san-stepper #stepperComponent [showSteps]="false"
                       [hideBottomButtons]="true" class="mh-100">
            <san-step *ngIf="!login" #stepWillkommen>
              <san-willkommen
                (anmeldenClicked)="anmeldenAngeklickt()"></san-willkommen>
            </san-step>
            <san-step *ngIf="!login" #stepArztdaten>
              <san-infotext-arztdaten-qrtne [state]="arztDatenResponseMitInputForm" #infotextArztdatenQRTNEComponent
                                            [uuid]="userParams.uuid"
                                            [krankenkassen]="krankenkassen"
                                            [versorgungsVertragID]="userParams.versorgungsVertragID"
                                            [vertragsgruppe]="userParams.vertragsgruppe"
                                            (weiterClicked)="arztBestaetigtGeklickt()"></san-infotext-arztdaten-qrtne>
            </san-step>
            <san-step *ngIf="!login" #stepBasisdatenZwei>
              <san-basis-daten-eingabe-zwei [state]="arztDatenResponseMitInputForm"
                                            (ready)="stepperComponent.next()"
                                            [krankenkassen]="krankenkassen"
                                            (zurueck)="zurueck()"
                                            [baseDataForm]="$any(arztDatenResponseMitInputForm.input_form)"
                                            [userParams]="userParams"
                                            (invalid)="goToErrorArztNichtEingeschrieben($event)">
              </san-basis-daten-eingabe-zwei>
            </san-step>
            <san-step *ngIf="!login" #stepBasisdaten>
              <san-basis-daten-eingabe [state]="arztDatenResponseMitInputForm"
                                       (ready)="stepperComponent.next()"
                                       [krankenkassen]="krankenkassen"
                                       (zurueck)="zurueck()"
                                       [baseDataForm]="$any(arztDatenResponseMitInputForm.input_form)"></san-basis-daten-eingabe>
            </san-step>
            <san-step *ngIf="!login" #stepTnz>
              <san-tnzeingabe [state]="arztDatenResponseMitInputForm"
                              (ready)="setTnz() && stepperComponent.next()"
                              [tnzBeginn]="$any(tnz_form.get('tnz_beginn'))"
                              (zurueck)="zurueck()"
              ></san-tnzeingabe>
            </san-step>
            <san-step *ngIf="!login" #stepZustimmung>
              <san-zustimmung-digi-tne-zwei [state]="arztDatenResponseMitInputForm" [sending]="sending"
                                            (bestaetigenClicked)="einschreiben($event)"
                                            (zurueck)="zurueck()"></san-zustimmung-digi-tne-zwei>
            </san-step>
            <san-step *ngIf="!login" #stepZustimmungBestaetigung>
              <san-zustimmung-bestaetigung-digi-tne-zwei
                [versicherten_nummer]="arztDatenResponseMitInputForm.input_form.get('versicherten_nummer').value"
                [geburtsdatum]="arztDatenResponseMitInputForm.input_form.get('geburtsdatum').value"
                [state]="previewState"
                [widerruf]="showLinkToWiderruf"
                [teilnahmeerklaerung]="previewState?.teilnahmeerklaerung_abrufbar"
                (abmeldenClicked)="abmelden()"
                (widerrufClicked)="widerruf()"
                (downloadTneClicked)="downloadTNE()"></san-zustimmung-bestaetigung-digi-tne-zwei>
            </san-step>
            <san-step #stepLogin>
              <san-login (anmeldenClicked)="checkLogin($event)"></san-login>
            </san-step>
            <san-step #stepZustimmungBestaetigungNachLogin>
              <san-zustimmung-bestaetigung-digi-tne-zwei
                [versicherten_nummer]="arztDatenResponseMitInputForm.input_form.get('versicherten_nummer').value"
                [geburtsdatum]="arztDatenResponseMitInputForm.input_form.get('geburtsdatum').value"
                [state]="previewState"
                [widerruf]="showLinkToWiderruf"
                [teilnahmeerklaerung]="previewState?.teilnahmeerklaerung_abrufbar"
                (abmeldenClicked)="abmelden()"
                (widerrufClicked)="widerruf()"
                (downloadTneClicked)="downloadTNE()"></san-zustimmung-bestaetigung-digi-tne-zwei>
            </san-step>
            <san-step #stepWiderruf>
              <san-widerruf [credentials]="creds"
                            (abmeldenClicked)="abmelden()"
                            [teilnahmeerklaerung]="state?.teilnahmeerklaerung_abrufbar"
                            [state]="state"
                            (downloadTneClicked)="downloadTNE()"
                            (bestaetigenClicked)="widerrufBestaetigen()"></san-widerruf>
            </san-step>
            <san-step #stepWiderrufBestaetigung>
              <san-widerruf-bestaetigung [teilnahmeerklaerung]="state?.teilnahmeerklaerung_abrufbar"
                                         [state]="state"
                                         (abmeldenClicked)="abmelden()"
                                         (downloadTneClicked)="downloadTNE()"></san-widerruf-bestaetigung>
            </san-step>
            <san-step #stepFehlerNichtEingeschrieben>
              <san-fehler-arzt-nicht-eingeschrieben
                [issue]="issue"
                (zurueck)="zurueckVonError()"></san-fehler-arzt-nicht-eingeschrieben>
            </san-step>
          </san-stepper>
        </ng-template>
      </div>
    </div>
  </div>
</div>
