export enum LoadingState {
  Init,
  Loading,
  Loaded,
  Error,
}

export interface Ordering<T> {
  inverse: boolean;
  params: T;
}

export function resetOrAddPageWRTPageSize(s, action: { page: { pageSize: number, pageNumber: number } }) {
  if (s.pageSize === action.page.pageSize) {
    s.pages[action.page.pageNumber] = action.page;
    return false;
  } else {
    s.pages = {};
    s.pages[action.page.pageNumber] = action.page;
    s.pageSize = action.page.pageSize;
    return true;
  }
}

export function resetOrAddPagesWRTOrdering(s, action: { page: { pageNumber: number, ordering: any } }) {
  if (s.ordering?.params === action.page.ordering?.params && s.ordering?.inverse === action.page.ordering?.inverse) {
    s.pages[action.page.pageNumber] = action.page;
    return false;
  } else {
    s.pages = {};
    s.pages[action.page.pageNumber] = action.page;
    s.ordering = action.page.ordering;
    return true;
  }
}

export function resetOrAddPagesWRTFilterObject(s: {
  pages: any,
  filterObject: { [key: string]: any }
},                                             action: {
  page: {
    pageNumber: number,
    filterObject: { [key: string]: any }
  }
}) {


  if (JSON.stringify(s.filterObject) === JSON.stringify(action.page.filterObject)) {
    s.pages[action.page.pageNumber] = action.page;
    return false;
  } else {
    s.pages = {};
    s.pages[action.page.pageNumber] = action.page;
    s.filterObject = action.page.filterObject;
    return true;
  }
}

export function resetOrAddPagesWRTFilterValue(s, action: { page: { pageNumber: number, filterValue: string } }) {
  if (s.filterValue === action.page.filterValue) {
    s.pages[action.page.pageNumber] = action.page;
    return false;
  } else {
    s.pages = {};
    s.pages[action.page.pageNumber] = action.page;
    s.filterValue = action.page.filterValue;
    return true;
  }
}
