import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {PreviewResponse} from '../../api/client.service';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-zustimmung',
  templateUrl: './zustimmung.component.html',
  styleUrls: ['./zustimmung.component.scss']
})
export class ZustimmungComponent implements OnInit {
  @Input() token: string;
  @Input() pin: string;
  @Input() state: PreviewResponse;
  @Input() sending = false;

  @Output() bestaetigenClicked = new EventEmitter<{ zustimmungEvaluation: boolean }>();

  spinnerIcon = faSpinner;

  bed1 = false;
  bed2 = false;
  zustimmungEvaluation = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onBestaetigenClicked() {
    if (this.sending || !this.bed1 || !this.bed2) {
      return;
    }
    this.bestaetigenClicked.emit({
      zustimmungEvaluation: this.zustimmungEvaluation,
    });
  }
}
